"use strict";
/**
 * LayoutDesigner client logic module

 * The layout designer client logic event handlers are dependent on the dhtmlx designer
 * so the event handlers had to be seperated to the layout and form designer client logic.
 * In the future, after the dhtmlx layout designer is removed, all the client logic
 * will be inserted into the graph editor controller.
 */
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * LayoutDesigner client logic class
 * @export
 * @class LayoutDesigner
 */
class LayoutDesigner {
    /**
     * LayoutDesigner onInitialize event handler
     * @static
     * @param {akioma.swat.GraphEditor} graphEditor
     * @memberof GraphLayoutEditor
     */
    static onInitialize(container) {
        const graphEditor = container.getObject('SimpleSwatGraphEditor');
        const objectGrid = container.getGrid('ObjectMasterGrid');
        const designer = container.getObject('SimpleSwatLayoutDesign');
        graphEditor.controller.dhx.addEventListener('drop', (evt) => {
            this.onDrop(objectGrid, designer, evt);
        });
        graphEditor.controller.dhx.addEventListener('droppanel', (evt) => {
            this.onPanelDrop(objectGrid, designer, evt);
        });
        graphEditor.controller.dhx.addEventListener('selectcell', (evt) => {
            this.setDesignerCursor(designer, evt);
        });
    }
    /**
     * onDrop event handler
     * @static
     * @param {akioma.swat.Grid} grid
     * @param {akioma.swat.SwatObject} designer
     * @param {MouseEvent} evt
     * @memberof GraphLayoutEditor
     */
    static onDrop(grid, designer, evt) {
        const { dragData, dropData: { guid: dropGuid } } = evt.detail;
        const tree = designer.controller.dhx.root.dataObj.cdata.a.dataObj;
        const source = grid.controller.dhx;
        const sourceData = grid.controller.dataSource.dhx.data.pull;
        const sourceId = Object.keys(sourceData).find(id => (sourceData[id].objectmasterguid === dragData.objectmasterguid));
        const target = tree;
        const targetData = designer.controller.dhx.getService('UIState').pull;
        const targetId = Object.keys(targetData).find(id => (targetData[id].guid === dropGuid));
        tree.callEvent('onDrag', [sourceId, targetId, null, source, target]);
    }
    /**
     * onPanelDrop event handler
     * @static
     * @param {akioma.swat.Grid} grid
     * @param {akioma.swat.SwatObject} designer
     * @param {MouseEvent} evt
     * @memberof GraphLayoutEditor
     */
    static onPanelDrop(grid, designer, evt) {
        const { dragData, dropParentData: { guid: dropParentGuid }, dropLayoutPosition } = evt.detail;
        const tree = designer.controller.dhx.root.dataObj.cdata.a.dataObj;
        const source = grid.controller.dhx;
        const sourceData = grid.controller.dataSource.dhx.data.pull;
        const sourceId = Object.keys(sourceData).find(id => (sourceData[id].objectmasterguid === dragData.objectmasterguid));
        const target = tree;
        const targetData = designer.controller.dhx.getService('UIState').pull;
        const targetParentId = Object.keys(targetData).find(id => (targetData[id].guid === dropParentGuid));
        // since the dhtmlx layout designer preview pane was removed
        // the code below is needed to normalize target data
        Object.keys(targetData).filter(id => (targetData[id].$parent == targetParentId)).map(id => (targetData[id])).reduce((ret, data) => {
            if (data.ui === 'empty')
                ret = data.id = data.position;
            else if (data.position && isNaN(data.position))
                ret = data.position;
            return ret;
        }, '');
        const targetId = Object.keys(targetData).find(id => (targetData[id].$parent == targetParentId && targetData[id].id === dropLayoutPosition));
        tree.callEvent('onDrag', [sourceId, targetId, null, source, target]);
    }
    /**
     * Set designer cursor
     * @static
     * @param {akioma.swat.SwatObject} designer
     * @param {MouseEvent} evt
     * @returns
     * @memberof GraphLayoutEditor
     */
    static setDesignerCursor(designer, evt) {
        const { guid } = evt.detail;
        if (!guid)
            return;
        const service = designer.controller.dhx.getService('UIState');
        const id = Object.keys(service.pull).find(id => (service.pull[id].guid === guid));
        service.setCursor(id);
    }
}
exports.default = LayoutDesigner;
