"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const graph_1 = require("./graph");
class Draggable {
    /**
     * Constructor
     * @param {mxGraph} graph The mxGraph object
     * @param {*} scope The scope to eval code in
     */
    constructor(graph, scope) {
        /**
         * The mxGraph object
         */
        this.graph = null;
        /**
         * The mxGraph bounding rectangle
         */
        this.graphRect = null;
        /**
         * The scope to eval code in
         */
        this.scope = null;
        /**
         * The drag element
         */
        this.dragEl = null;
        /**
         * The drag element X position
         */
        this.dragElX = 0;
        /**
         * The drag element Y position
         */
        this.dragElY = 0;
        /**
         * The drag element move timeout
         */
        this.dragElTimeout = null;
        /**
         * The drag element offset x
         */
        this.dragElOffsetX = 0;
        /**
         * The drag element offsey y
         */
        this.dragElOffsetY = 0;
        /**
         * The drag target (set at mouse down)
         */
        this.dragTarget = null;
        /**
         * The drag target data (set at mouse down)
         */
        this.dragTargetData = null;
        /**
         * The current highlighted cell
         */
        this.highlightedCell = null;
        /**
         * The current highlighted cell original style
         */
        this.highlightedCellVertex = null;
        /**
         * Flag indicating the move is active (started)
         */
        this.isActive = false;
        this.scope = scope || this;
        this.graph = graph;
        this.mouseDownHandler = this.mouseDownHandler.bind(this);
        this.mouseMoveHandler = this.mouseMoveHandler.bind(this);
        this.mouseUpHandler = this.mouseUpHandler.bind(this);
    }
    /**
     * Mouse down event handler
     * @param {MouseEvent} evt The mouse event
     */
    mouseDownHandler(evt) {
        this.graphRect = this.graph.container.getBoundingClientRect();
        ({
            target: this.dragTarget,
            data: this.dragTargetData
        } = this.getDragTarget(evt.target, evt));
        if (!this.dragTarget)
            return;
        ({
            dragEl: this.dragEl,
            offsetX: this.dragElOffsetX,
            offsetY: this.dragElOffsetY
        } = this.createDragEl(this.dragTarget, this.dragTargetData, evt));
        if (this.dragEl) {
            this.dragEl.style.position = 'absolute';
            this.dragEl.style.zIndex = '99999';
            this.dragEl.style.left = '-1000px';
            this.dragEl.style.top = '-1000px';
            this.dragElOffsetX = this.dragElOffsetX || (this.dragEl.offsetWidth / 2);
            this.dragElOffsetY = this.dragElOffsetY || (this.dragEl.offsetHeight / 2);
        }
        this.isActive = true;
    }
    /**
     * Mouse move event handler
     * @param {MouseEvent} evt The mouse event
     */
    mouseMoveHandler(evt) {
        if (!this.isActive)
            return;
        if (evt.buttons === 0) {
            this.stop();
            return;
        }
        this.moveDragEl(evt.pageX, evt.pageY);
        const cell = this.getCellByPos(evt.pageX, evt.pageY);
        if (this.highlightedCell !== cell) {
            const isValid = this.isTargetValid(cell, this.dragTargetData);
            this.clearHighlight();
            this.highlightCell(cell, isValid);
        }
    }
    /**
     * Mouse up event handler (drop)
     * @param {MouseEvent} evt The mouse event
     */
    mouseUpHandler(evt) {
        if (!this.isActive)
            return;
        const cell = this.getCellByPos(evt.pageX, evt.pageY);
        if (cell) {
            const isValid = this.isTargetValid(cell, this.dragTargetData);
            if (isValid)
                this.dropHandler(cell, evt);
        }
        this.stop();
    }
    /**
     * Highlights a cell (according to the valid flag)
     * @param {mxCell} cell The cell to highlight
     * @param {boolean} isValid The move target valid flag
     */
    highlightCell(cell, isValid) {
        if (!cell)
            return;
        const rect = (0, graph_1.getBoundingRect)(this.graph, cell);
        this.highlightedCell = cell;
        this.highlightedCellVertex = this.graph.insertVertex(this.graph.getModel().root, `${cell.getId()}_highlight`, '', rect.x, rect.y, rect.width, rect.height, isValid ? 'validHighlight' : 'invalidHighlight');
    }
    /**
     * Clears the highlighted cell (returns the original style)
     */
    clearHighlight() {
        if (!this.highlightedCell)
            return;
        this.graph.removeCells([this.highlightedCellVertex], false);
        this.highlightedCell = null;
        this.highlightedCellVertex = null;
    }
    /**
     * Get cell by x, y position
     * @param {number} x The x position (relative to the graph)
     * @param {number} y The y position (relative to the graph)
     * @returns {mxCell} The cell
     */
    getCellByPos(x, y) {
        const container = this.graph.container;
        x -= this.graphRect.x;
        y -= this.graphRect.y;
        if (x < 0 || x > container.clientWidth)
            return null;
        if (y < 0 || y > container.clientHeight)
            return null;
        x += container.scrollLeft;
        y += container.scrollTop;
        let cell = this.graph.getCellAt(x, y, this.graph.getDefaultParent());
        if (!cell)
            return null;
        while (cell && !(typeof cell.value === 'object' && (cell.value.nodeName === 'repository' || cell.value.nodeName === 'panel')))
            cell = cell.parent;
        return cell;
    }
    /**
     * Moves the drag element
     * @param {number} pageX The x pos relative to the viewport
     * @param {number} pageY The y pos relative to the viewport
     */
    moveDragEl(pageX, pageY) {
        if (!this.dragEl)
            return;
        this.dragElX = pageX - this.dragElOffsetX;
        this.dragElY = pageY - this.dragElOffsetY;
        if (!this.dragElTimeout) {
            this.dragElTimeout = setTimeout(() => {
                this.dragEl.style.left = `${this.dragElX}px`;
                this.dragEl.style.top = `${this.dragElY}px`;
                this.dragElTimeout = null;
            }, 50);
        }
    }
    /**
     * Removed or deletes the drag element
     */
    removeDragEl() {
        if (!this.dragEl)
            return;
        this.dragEl.remove();
        this.dragEl = null;
        clearTimeout(this.dragElTimeout);
        this.dragElTimeout = null;
    }
    /**
     * Stops or resets the move.
     */
    stop() {
        if (!this.isActive)
            return;
        this.clearHighlight();
        this.removeDragEl();
        this.isActive = false;
    }
    /**
     * Destroys or disables the move
     */
    destroy() {
        this.stop();
    }
}
exports.default = Draggable;
