"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setImmediate = exports.resetTimeout = exports.clone = exports.evalCode = exports.copy = exports.isEqual = void 0;
/**
 * Utils module
 */
const templates_1 = require("./templates");
// re-export the isEqual function
var lodash_1 = require("lodash");
Object.defineProperty(exports, "isEqual", { enumerable: true, get: function () { return lodash_1.isEqual; } });
// re-export the extend as copy function
var jquery_1 = require("jquery");
Object.defineProperty(exports, "copy", { enumerable: true, get: function () { return jquery_1.extend; } });
/**
 * Eval code strings
 * @param {string} code The code string to eval
 * @param {*} data The data to pass to the code
 * @param {*} scope The scope to bind the code
 * @param {string} errDesc The error description
 * @param {*} errRet The error return value
 * @returns The eval code result
 */
function evalCode(code, data, scope, errDesc, errRet) {
    data = Object.assign(data || {}, { isBlankOrNull: templates_1.isBlankOrNull, isData: templates_1.isData, isVisual: templates_1.isVisual, isPanel: templates_1.isPanel, isField: templates_1.isField, isBlock: templates_1.isBlock, isNavigation: templates_1.isNavigation, isTabbar: templates_1.isTabbar, isLayout: templates_1.isLayout, getType: templates_1.getType });
    scope = scope || this;
    errDesc += `${errDesc ? ' ' : ''}code`;
    // alternative to using with
    const cmd = `(({ ${Object.keys(data).join(', ')} }) => { return ${code} })(data);`;
    try {
        // the parameter is required in the eval scope
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return (function (data) {
            return eval(cmd);
        }).bind(scope)(data);
    }
    catch (err) {
        console.error(`Failed to run ${errDesc}`, code, err);
        return errRet;
    }
}
exports.evalCode = evalCode;
/**
 * Creates a new copy of an object
 * @param {*} source The source object
 * @returns {*} The new copied object
 */
function clone(source) {
    return jQuery.extend(true, {}, source);
}
exports.clone = clone;
/**
 * Resets the timeout used for throttling
 * @param {() => void} callback The timeout callback function
 * @param {number} ms The timeout in milliseconds
 * @param {NodeJS.Timeout} id The previous timeout id
 * @returns {NodeJS.Timeout} Returns the current timeout id
 */
function resetTimeout(callback, ms, id) {
    if (id) {
        clearTimeout(id);
        id = null;
    }
    id = setTimeout(() => {
        callback();
        id = null;
    }, ms);
    return id;
}
exports.resetTimeout = resetTimeout;
/**
 * Runs a callback function immediately after the current operation completed.
 * @param {() => void} callback The timeout callback function
 * @returns {NodeJS.Timeout} Returns the current timeout id
 */
function setImmediate(callback) {
    return setTimeout(callback, 0);
}
exports.setImmediate = setImmediate;
