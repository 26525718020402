"use strict";
/**
 * PropertyGrid client logic module
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Attribute class
 */
class Attribute {
}
/**
 * Attribues class
 */
class Attributes {
    constructor() {
        this.attributes = [];
        this.attributesById = {};
        this.source = null;
        this.guid = null;
    }
}
/**
 * State class
 */
class State {
    constructor() {
        this.data = {};
        this.updatedRows = {};
        this.selectedGuid = null;
    }
}
/**
 * PropertyGrid client logic class
 * @export
 * @class PropertyGrid
 */
class PropertyGrid {
    /**
     * PropertyGrid onInitialize event handler
     * @static
     * @param {akioma.swat.SwatObject} propertyGrid
     * @memberof PropertyGrid
     */
    static onInitialize(propertyGrid, graphEditor, dataSource) {
        const state = new State;
        (propertyGrid.controller.customData || (propertyGrid.controller.customData = {})).state = state;
        graphEditor.controller.dhx.addEventListener('selectcell', (evt) => {
            this.onSelectCell(propertyGrid, dataSource, evt);
        });
        propertyGrid.controller.dhx.attachEvent('onEditCell', (stage, rowId, colIdx) => this.onEditCell(propertyGrid, graphEditor, dataSource, stage, rowId, colIdx));
    }
    /**
     * SelectCell event handler
     * @static
     * @param {akioma.swat.SwatObject} propertyGrid
     * @param {akioma.swat.DataSource} dataSource
     * @param {MouseEvent} evt
     * @memberof PropertyGrid
     */
    static onSelectCell(propertyGrid, dataSource, evt) {
        const state = propertyGrid.controller.customData.state;
        const { guid } = evt.detail;
        window.akioma.repository.positionDesignerCursor({
            dataSource: dataSource.dynObject,
            propertyGrid: propertyGrid.dynObject,
            guid
        });
        state.selectedGuid = guid;
    }
    /**
     * EditCell event handler
     * @static
     * @param {akioma.swat.SwatObject} propertyGrid
     * @param {number} stage
     * @param {string} rowId
     * @param {number} colIdx
     * @param {*} newValue
     * @param {*} oldValue
     * @returns
     * @memberof PropertyGrid
     */
    static onEditCell(propertyGrid, graphEditor, dataSource, stage, rowId, colIdx) {
        const propStore = propertyGrid.controller.propStore;
        const gridCell = propertyGrid.controller.dhx.cells(rowId, colIdx);
        const colId = propertyGrid.controller.dhx.getColumnId(colIdx);
        if (stage === 2 || stage === 1 && gridCell.isCheckbox()) {
            propStore.data.pull[rowId][colId] = gridCell.getValue();
            // if inherited is changed do not reset
            if (colId !== 'isinherited')
                propStore.data.pull[rowId].isinherited = false;
            this.updateAttributes(propertyGrid, graphEditor, dataSource, rowId);
        }
        return true;
    }
    /**
     * Display attributes in property grid
     * @static
     * @deprecated
     * @param {akioma.swat.SwatObject} propertyGrid
     * @param {Attribute[]} attributes
     * @memberof PropertyGrid
     */
    static displayAttributes(propertyGrid) {
        const state = propertyGrid.controller.customData.state;
        const attributes = state.data[state.selectedGuid].attributes;
        const store = propertyGrid.controller.propStore;
        const dhx = propertyGrid.controller.dhx;
        // convert field names to lower case
        const data = attributes.map(attribute => Object.keys(attribute).reduce((ret, key) => {
            ret[key.toLowerCase()] = attribute[key];
            return ret;
        }, {}));
        // set attribute value from type value
        data.forEach(rec => {
            rec.id = rec.attributelabel.toLowerCase();
            rec.repositorytype = rec.repositorytype.toUpperCase();
            rec.attributevalue = rec[`${rec.repositorytype.toLowerCase()}value`];
        });
        // load data
        store.clearAll();
        store.parse(data);
        // set dhtmlx type by repository type
        const dhxType = {
            'character': 'ed',
            'logical': 'ch',
            'decimal': 'edn',
            'integer': 'edn',
            'date': 'dhxCalendarA',
            'datetime': 'dhxCalendarA'
        };
        const colIdx = dhx.getColIndexById('attributevalue');
        Object.keys(store.data.pull).forEach(rowId => {
            const rec = store.data.pull[rowId];
            const type = dhxType[rec.repositorytype.toLowerCase()];
            dhx.setCellExcellType(rowId, colIdx, type);
        });
    }
    static updateAttributes(propertyGrid, graphEditor, dataSource, rowId) {
        const state = propertyGrid.controller.customData.state;
        const guid = state.selectedGuid;
        const source = state.data[guid].source;
        const attribute = state.data[guid].attributesById[rowId];
        const prop = propertyGrid.controller.propStore.data.pull[rowId];
        prop.repositorytype = prop.repositorytype.toUpperCase();
        prop[`${prop.repositorytype.toLowerCase()}value`] = prop.attributevalue;
        Object.keys(attribute).forEach(key => {
            if (prop[key.toLowerCase()] !== undefined)
                attribute[key] = prop[key.toLowerCase()];
        });
        if (rowId !== 'name')
            state.updatedRows[guid] = state.data[guid];
        if (rowId === 'name') {
            if (source === 'master') {
                const store = dataSource.controller.getStore('eSmartObjectMaster');
                const data = Object.keys(store.data.pull).map(key => store.data.pull[key]);
                const rec = data.find(rec => rec.objectmasterguid === guid);
                rec.objectmastername = rec.objectname = attribute.CharacterValue;
                store.update(rec.id, rec);
                graphEditor.controller.setState({ master: data[0] });
            }
            else {
                const store = dataSource.controller.getStore('eSmartObjectInstance');
                const data = Object.keys(store.data.pull).map(key => store.data.pull[key]);
                const rec = data.find(rec => rec.objectinstanceguid === guid);
                rec.instancename = attribute.CharacterValue;
                store.update(rec.id, rec);
                graphEditor.controller.setState({ instances: data });
            }
        }
    }
    /**
     * Fetch attributes
     * @static
     * @param {akioma.swat.SwatObject} propertyGrid
     * @param {akioma.swat.DataSource} dataSource
     * @param {Source} source
     * @param {string} guid
     * @deprecated
     * @returns {Promise<any>}
     * @memberof PropertyGrid
     */
    static fetchAttributes(propertyGrid, dataSource, source, guid) {
        return __awaiter(this, void 0, void 0, function* () {
            const state = propertyGrid.controller.customData.state;
            if (!state.data[guid]) {
                if (source === 'master') {
                    const attributes = yield this.fetchMasterAttributes(propertyGrid, dataSource, guid);
                    const store = dataSource.controller.getStore('eSmartObjectMaster');
                    const data = Object.keys(store.data.pull).map(key => store.data.pull[key]);
                    const rec = data.find(rec => rec.objectmasterguid === guid);
                    attributes.push({
                        AttributeValueGuid: '',
                        AttributeLabel: 'name',
                        RepositoryType: 'Character',
                        CharacterValue: rec.objectname,
                        ObjectInstanceGuid: guid,
                        ObjectMasterGuid: rec.objectmasterguid,
                        ObjectTypeGuid: '',
                        LogicalValue: false,
                        IsInherited: false,
                        TechnicalName: 'ObjectMasterName',
                        PropertyOrEvent: true,
                        AppliesAtRuntime: true,
                        ConstantValue: false
                    });
                    this.addAttributes(state, attributes, source, guid);
                }
                else {
                    const attributes = yield this.fetchInstanceAttributes(propertyGrid, dataSource, guid);
                    const store = dataSource.controller.getStore('eSmartObjectInstance');
                    const data = Object.keys(store.data.pull).map(key => store.data.pull[key]);
                    const rec = data.find(rec => rec.objectinstanceguid === guid);
                    attributes.push({
                        AttributeValueGuid: '',
                        AttributeLabel: 'ObjectMasterName',
                        RepositoryType: 'Character',
                        CharacterValue: rec.objectmastername,
                        ObjectInstanceGuid: guid,
                        ObjectMasterGuid: rec.objectmasterguid,
                        ObjectTypeGuid: '',
                        LogicalValue: false,
                        IsInherited: false,
                        TechnicalName: 'ObjectMasterName',
                        PropertyOrEvent: true,
                        AppliesAtRuntime: true,
                        ConstantValue: false
                    });
                    attributes.push({
                        AttributeValueGuid: '',
                        AttributeLabel: 'name',
                        RepositoryType: 'Character',
                        CharacterValue: rec.instancename,
                        ObjectInstanceGuid: guid,
                        ObjectMasterGuid: '',
                        ObjectTypeGuid: '',
                        LogicalValue: false,
                        IsInherited: false,
                        TechnicalName: 'name',
                        PropertyOrEvent: true,
                        AppliesAtRuntime: true,
                        ConstantValue: false
                    });
                    this.addAttributes(state, attributes, source, guid);
                }
            }
        });
    }
    /**
     * Fetch new attributes from master and attaches to instance
     * @static
     * @param {State} state
     * @param {akioma.swat.SwatObject} propertyGrid
     * @param {akioma.swat.DataSource} dataSource
     * @param {string} guid
     * @returns
     * @memberof PropertyGrid
     */
    static fetchNewAttributes(propertyGrid, dataSource, guid) {
        return __awaiter(this, void 0, void 0, function* () {
            const state = propertyGrid.controller.customData.state;
            const store = dataSource.controller.getStore('eSmartObjectInstance');
            const data = Object.keys(store.data.pull).map(key => store.data.pull[key]);
            const rec = data.find(rec => rec.objectinstanceguid === guid);
            const masterGuid = rec.objectmasterguid;
            const instanceGuid = rec.objectinstanceguid;
            // const attributes = await this.fetchMasterAttributes(propertyGrid, dataSource, masterGuid);
            propertyGrid.controller.opt.getValuesMethodName = 'GetObjectMasterDesignAttributes';
            propertyGrid.controller.opt.entityName = 'eSmartObjectMaster';
            propertyGrid.controller.opt.onAfterGetValues = '$ akioma.repository.onAfterGetValuesInsProps(self)';
            const designer = dataSource.dynObject.getLink('DATA:TARGET');
            designer.controller.newInstances.push(instanceGuid);
            designer.controller.lastSelectedInstance = instanceGuid;
            const attributes = yield propertyGrid.controller.getProperties(masterGuid);
            attributes.push({
                AttributeValueGuid: '',
                AttributeLabel: 'ObjectMasterName',
                RepositoryType: 'Character',
                CharacterValue: rec.objectmastername,
                ObjectInstanceGuid: guid,
                ObjectMasterGuid: rec.objectmasterguid,
                ObjectTypeGuid: '',
                LogicalValue: false,
                IsInherited: false,
                TechnicalName: 'ObjectMasterName',
                PropertyOrEvent: true,
                AppliesAtRuntime: true,
                ConstantValue: false
            });
            attributes.push({
                AttributeValueGuid: '',
                AttributeLabel: 'name',
                RepositoryType: 'Character',
                CharacterValue: rec.instancename,
                ObjectInstanceGuid: guid,
                ObjectMasterGuid: '',
                ObjectTypeGuid: '',
                LogicalValue: false,
                IsInherited: false,
                TechnicalName: 'name',
                PropertyOrEvent: true,
                AppliesAtRuntime: true,
                ConstantValue: false
            });
            this.addAttributes(state, attributes, 'instance', instanceGuid);
            // adds the new instance attributes to the updated rows
            state.updatedRows[instanceGuid] = state.data[instanceGuid];
        });
    }
    /**
     * Fetch master attributes
     * @static
     * @param {akioma.swat.SwatObject} propertyGrid
     * @param {akioma.swat.DataSource} dataSource
     * @param {string} guid
     * @returns {Promise<Attribute[]>}
     * @memberof PropertyGrid
     */
    static fetchMasterAttributes(propertyGrid, dataSource, guid) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield akioma.swat.App.invokeServerTask({
                name: 'Akioma.Swat.Repository.AttributeValuesTask',
                methodName: 'GetObjectMasterDesignAttributes',
                paramObj: { plcParameter: { ObjectMasterGuid: guid } }
            });
            return result.dsDesignAttributeValue.dsDesignAttributeValue.eDesignAttributeValue;
        });
    }
    /**
     * Fetch instance attributes
     * @static
     * @param {akioma.swat.SwatObject} propertyGrid
     * @param {akioma.swat.DataSource} dataSource
     * @param {string} guid
     * @returns {Promise<Attribute[]>}
     * @memberof PropertyGrid
     */
    static fetchInstanceAttributes(propertyGrid, dataSource, guid) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield akioma.swat.App.invokeServerTask({
                name: 'Akioma.Swat.Repository.AttributeValuesTask',
                methodName: 'GetObjectInstancesDesignAttributes',
                paramObj: { plcParameter: { ObjectInstanceGuids: guid } },
                showWaitCursor: true,
                uiContext: propertyGrid
            });
            return result.dsDesignAttributeValue.dsDesignAttributeValue.eDesignAttributeValue;
        });
    }
    /**
     * Add attributes to state
     * @static
     * @param {State} state
     * @param {Attribute[]} attributes
     * @param {Source} source
     * @param {string} guid
     * @memberof PropertyGrid
     */
    static addAttributes(state, attributes, source, guid) {
        const sortByValue = {
            'name': '0',
            'ObjectMasterName': '1',
            'Layout': '2'
        };
        state.data[guid] = {
            attributes: attributes.sort(({ AttributeLabel: a }, { AttributeLabel: b }) => {
                a = sortByValue[a] || a.toLowerCase();
                b = sortByValue[b] || b.toLowerCase();
                if (a < b)
                    return -1;
                if (a > b)
                    return 1;
                return 0;
            }),
            attributesById: attributes.reduce((ret, attribute) => {
                ret[attribute.AttributeLabel.toLowerCase()] = attribute;
                return ret;
            }, {}),
            source,
            guid
        };
    }
    /**
     * Save all updated attributes
     * @static
     * @param {akioma.swat.SwatObject} propertyGrid
     * @memberof PropertyGrid
     */
    static saveAttributes(propertyGrid) {
        return __awaiter(this, void 0, void 0, function* () {
            const state = propertyGrid.controller.customData.state;
            for (const key in state.updatedRows) {
                const attributes = state.updatedRows[key];
                const data = attributes.attributes.filter(attribute => attribute.AttributeLabel !== 'name' &&
                    attribute.AttributeLabel !== 'ObjectMasterName' &&
                    attribute.AttributeLabel !== 'Layout');
                if (attributes.source === 'master')
                    yield this.saveMasterAttributes(propertyGrid, data, attributes.guid);
                else
                    yield this.saveInstanceAttributes(propertyGrid, data, attributes.guid);
            }
            state.updatedRows = {};
        });
    }
    /**
     * Save master attributes
     * @static
     * @param {akioma.swat.SwatObject} propertyGrid
     * @param {Attribute[]} attributes
     * @param {string} guid
     * @memberof PropertyGrid
     */
    static saveMasterAttributes(propertyGrid, attributes, guid) {
        return __awaiter(this, void 0, void 0, function* () {
            yield akioma.swat.App.invokeServerTask({
                name: 'Akioma.Swat.Repository.AttributeValuesTask',
                methodName: 'UpdateObjectMasterDesignAttributes',
                paramObj: {
                    plcParameter: { ObjectMasterGuid: guid },
                    dsDesignAttributeValue: { dsDesignAttributeValue: { eDesignAttributeValue: attributes } }
                },
                showWaitCursor: true,
                uiContext: propertyGrid
            });
        });
    }
    /**
     * Save instance attributes
     * @static
     * @param {akioma.swat.SwatObject} propertyGrid
     * @param {Attribute[]} attributes
     * @param {string} guid
     * @memberof PropertyGrid
     */
    static saveInstanceAttributes(propertyGrid, attributes, guid) {
        return __awaiter(this, void 0, void 0, function* () {
            yield akioma.swat.App.invokeServerTask({
                name: 'Akioma.Swat.Repository.AttributeValuesTask',
                methodName: 'UpdateObjectInstanceDesignAttributes',
                paramObj: {
                    plcParameter: { ObjectInstanceGuid: guid },
                    dsDesignAttributeValue: { dsDesignAttributeValue: { eDesignAttributeValue: attributes } }
                },
                showWaitCursor: true,
                uiContext: propertyGrid
            });
        });
    }
}
exports.default = PropertyGrid;
